
import { getDictWP,
         updateFvapLeosWithButter } from '~/utils/butterUtils'
import axios from 'axios'
import fieldLabelAndTooltip from '~/mixins/fieldLabelAndTooltip.js'

export default {
  name: 'Jurisdiction',
  mixins: [fieldLabelAndTooltip],
  props: [
    'fieldName',
    'value',
    'state',
    'butterLeos',
    'type',
    'label',
    'instructions',
    'toolTipTitle',
    'toolTipContent',
    'placeholder',
    'dict'
  ],
  async mounted () {
    this.loading = true

    if (this.state) {
      let fvapLeos = (await axios.get(`/leos/${this.state}-leos.json`)).data
      let ul = updateFvapLeosWithButter(fvapLeos, this.butterLeos)
      this.leos = ul.filter(leo => leo.fpcaOffice).sort((a,b)=>{ return (a.n<b.n)?-1:1})
    } else {
      this.leos = []
    }

    if (this.leos.length === 1) {
      this.isSingleLeoState = true
      this.updateLeo(this.leos[0])
    } else {
      this.isSingleLeoState = false
    }

    this.loading = false
  },
  data () {
    return {
      leos: [],
      isSingleLeoState: false,
      typedJurisdiction: '',
      isOpen: false,
      isOpen_Jurisdiction: false,
      isInFocus_Jurisdiction: false,
      loading: true
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    jurisdiction () {
      return this.leo && this.leo.n ? this.leo.n : ''
    },
    filteredLeos () {
      if (!this.typedJurisdiction) {
        return this.leos.filter(
          (e, i, a) =>
            !(
              i < 6 &&
              e.suggested &&
              a
                .slice(0, i)
                .map(x => x.i)
                .includes(e.i)
            )
        )
      }
      return this.leos.filter(
          leo =>
            leo.n.toLowerCase().includes(this.typedJurisdiction.toLowerCase()) ||
            leo.j.toLowerCase().includes(this.typedJurisdiction.toLowerCase())
        ).length === 1
        ? this.leos.filter(
            leo =>
              leo.n
                .toLowerCase()
                .includes(this.typedJurisdiction.toLowerCase()) ||
                leo.j
                  .toLowerCase().includes(this.typedJurisdiction.toLowerCase())
            )
          .concat(
            this.leos.filter(
              (e, i, a) =>
                !(
                  i < 6 &&
                    e.suggested &&
                    a
                      .slice(0, i)
                      .map(x => x.i)
                      .includes(e.i)
                )
            )
          )
        : this.leos.filter(
            leo =>
              leo.n
                .toLowerCase()
                .includes(this.typedJurisdiction.toLowerCase()) ||
                leo.j.toLowerCase().includes(this.typedJurisdiction.toLowerCase())
            )
    },
    selectedLeo () {
      return this.$store.state.leo.leo ? this.$store.state.leo.leo : {}
    },
  },
  methods: {
    decodeHtmlEntity (str) {
      str = str
        .replace(/&apos;/g, "'")
        .replace(/&quot;/g, '"')
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<')
        .replace(/&amp;/g, '&')
      return str.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec)
      })
    },
    updateLeo (value) {
      let leo = {}
      if (value) {
        Object.keys(value).forEach(x => {
          if (value[x]) leo[x] = value[x]
        })
      }
      delete leo.suggested
      this.$store.commit('saveLeo', { leo: leo })
      this.isOpen_Jurisdiction = false
    },
    updated: function () {
      this.$emit('input')
    },
    getDictWP(dictItem, paramObj) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictWP(dictItem, paramObj)" in the return statement is really
       * the function getDictWP that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictWP(dictItem, paramObj)
    },
  }
}
